import a from "../assets/uxe.ico";
import b from "../assets/loco.ico";
import c from "../assets/oxo.ico";
import d from "../assets/psdn.ico";
import e from "../assets/favicon.ico";
import bgfate from "../assets/bgfate.png";
import bgdn from "../assets/fatelostsaga.png";
import izar from "../assets/Izar.jpg";

export const posts = [
  {
    _id: "655b3f037a397a2c8546c2f5",
    title: "Fate Dragon Nest",
    slug: "Fate Dragon Nest",
    desc:
      '<p>Fate Dragon Nest Private Server Using SDO 556 by GameZone, Cap 99 With New Class Vandar: <a target="_blank" rel="noopener noreferrer nofollow" href="https://dnfate.fate-community.com/"><u>https://dnfate.fate-community.com</u> Server IP ID 85.111.231.1</a></p>\';\n' +
      "import React from 'react';\n" +
      "import {render} from 'react-dom';\n" +
      "\n" +
      "// surprise, it's a div instead!\n" +
      "const MyH1 = ({children, ...props}) =&gt; (&lt;div {...props}&gt;{children}&lt;/div&gt;);\n" +
      "\n" +
      "render((\n" +
      "    &lt;Markdown\n" +
      "        options={{\n" +
      "            overrides: {\n" +
      "                h1: {\n" +
      "                    component: MyH1,\n" +
      "                    props: {\n" +
      "                        className: 'foo',\n" +
      "                    },\n" +
      "                },\n" +
      "            },\n" +
      "        }}&gt;\n" +
      "        # Hello world!\n" +
      "    &lt;/Markdown&gt;\n" +
      "), document.body);\n" +
      "\n" +
      "/*\n" +
      "    renders:\n" +
      "\n" +
      '    &lt;div class="foo"&gt;\n' +
      "        Hello World\n" +
      "    &lt;/div&gt;\n" +
      ' */</code></pre><p style="text-align: start">HTML attributes can also be overridden, with a <a target="_blank" rel="noopener ugc nofollow" class="af lw" href="https://github.com/probablyup/markdown-to-jsx#usage"><u>few exceptions</u></a>.</p><h2 style="text-align: start"><strong>HTML is a-ok.</strong></h2><p style="text-align: start">Most markdown parsers steer clear of handling HTML and ones targeting JSX often have to resort to using <code>dangerouslySetInnerHTML</code>, an escape hatch that opens up your code to various security risks.</p><p style="text-align: start"><code>markdown-to-jsx</code> itself had to utilize this functionality in an earlier version, but as of v6 that is no longer the case! It’s all good now.</p><h2 style="text-align: start"><strong>Github-Flavored Markdown (GFM) syntaxes are supported.</strong></h2><p style="text-align: start">GFM <a target="_blank" rel="noopener ugc nofollow" class="af lw" href="https://github.github.com/gfm/"><u>adds a ton of useful functionality</u></a> to markdown like HTML tables, task lists, strikethrough formatting, and more.</p><p style="text-align: start">—</p><p style="text-align: start">All this weighs in at <a target="_blank" rel="noopener ugc nofollow" class="af lw" href="https://bundlephobia.com/result?p=markdown-to-jsx%406.0.3"><u>just over 5kB gzipped</u></a>.</p><p style="text-align: start">Special thanks are owed to the <a target="_blank" rel="noopener ugc nofollow" class="af lw" href="https://github.com/Khan/simple-markdown"><u>simple-markdown</u></a> team for creating an extremely simple, but highly-extensible markdown framework. A forked version of their parsing engine is the baseline for all the functionality above and much more to come. 🙏🏼</p><p style="text-align: start"><em>Check out my other project: </em><a target="_blank" rel="noopener ugc nofollow" class="af lw" href="https://buttermilk.js.org/"><u>buttermilk</u></a>, beautifully simple routing for React</p>',
    img: bgfate,
    cat: "FateDN",
    views:
      Array(50)[
        ("655ca253c2b11c0988aa3127",
        "655ca254c2b11c0988aa312c",
        "655ca25dc2b11c0988aa3131",
        "655ca25ec2b11c0988aa3136",
        "655ca2a17597bfc21e43f0ca",
        "655ca2a27597bfc21e43f0cf",
        "655ca2b87bd6931ee144606e",
        "655ca2cc7bd6931ee1446073",
        "655ca2cd7bd6931ee1446078",
        "655ca2d77bd6931ee144607d",
        "655ca2d87bd6931ee1446082",
        "655ca2ee7bd6931ee1446087",
        "655ca33ac70cdaf8d0abb200",
        "655ca33bc70cdaf8d0abb207",
        "655cabc55ff0ced1b1c50336",
        "655cabc65ff0ced1b1c5033d",
        "655cd5f6aad9ddee3dece7eb",
        "655cd5f7aad9ddee3dece7f2",
        "655cd685aad9ddee3dece7fd",
        "655cde4aa00890a1b49fcef7",
        "655cde4ba00890a1b49fcefe",
        "655ce4eade4a69fbc727ab7f",
        "655ce4ebde4a69fbc727ab86",
        "655ce520de4a69fbc727ab9c",
        "655ce521de4a69fbc727aba3",
        "655ce584de4a69fbc727abe4",
        "655ce585de4a69fbc727abeb",
        "655d82ec079fbf7891ad8af2",
        "655d82ed079fbf7891ad8afb",
        "655d832d079fbf7891ad8b29",
        "655d832e079fbf7891ad8b30",
        "655d8721079fbf7891ad8b4f",
        "655d8723079fbf7891ad8b56",
        "655d96a0079fbf7891ad8bd9",
        "655d96a1079fbf7891ad8be0",
        "655d9e49079fbf7891ad8c2b",
        "655d9e4a079fbf7891ad8c31",
        "655d9fff079fbf7891ad8c4e",
        "655da001079fbf7891ad8c55",
        "655da71e079fbf7891ad8cdf",
        "655da71f079fbf7891ad8ce6",
        "655da746079fbf7891ad8cf1",
        "655e2587d1d74ffbd27bc169",
        "655e2588d1d74ffbd27bc170",
        "655e2678d1d74ffbd27bc18f",
        "655e2679d1d74ffbd27bc196",
        "655ef1fac86d7a706c36f9b0",
        "655ef1fbc86d7a706c36f9bb",
        "655ef2bcc86d7a706c36f9c4",
        "655ef5b0c86d7a706c36f9cb")
      ],
    user: {
      _id: "655ad72bd148ee58ab8d5871",
      name: "Izareo Madelame",
      image: izar,
    },
    comments: ["655ca6965d5a025fa52b60c6", "655ce52bde4a69fbc727abac"],
    status: true,
    createdAt: "2024-09-20T11:12:03.368Z",
    updatedAt: "2023-09-23T06:48:16.785Z",
    __v: 0,
  },
  {
    _id: "655b21192255c0b35d4ab60b",
    title: "Lost Saga by Fate",
    slug: "Lost Saga by Fate",
    desc: "<p> Prepare for battle! The Fate Community proudly presents Lost Saga with the Korean Client – featuring new heroes, thrilling maps, and endless adventures. a seasoned warrior or a new recruit, there s a place for you in this epic arena.</p> ",
    img: bgdn,
    cat: "FASHION",
    views:
      Array(23)[
        ("655caeaf5ff0ced1b1c503fa",
        "655caeaf5ff0ced1b1c50401",
        "655caec15ff0ced1b1c50414",
        "655caec25ff0ced1b1c5041b",
        "655cd693aad9ddee3dece81c",
        "655cd695aad9ddee3dece825",
        "655ce546de4a69fbc727abc2",
        "655ce548de4a69fbc727abc9",
        "655da977079fbf7891ad8d09",
        "655db9ba079fbf7891ad8da5",
        "655db9bc079fbf7891ad8dae",
        "655dbe1a079fbf7891ad8e2c",
        "655dbe1b079fbf7891ad8e33",
        "655dbe5b079fbf7891ad8e3c",
        "655dbe8a079fbf7891ad8e43",
        "655dc180079fbf7891ad8e6c",
        "655dc181079fbf7891ad8e73",
        "655e270bd1d74ffbd27bc1ab",
        "655e270dd1d74ffbd27bc1b2",
        "655e2736d1d74ffbd27bc1bb",
        "655e2737d1d74ffbd27bc1c4",
        "655e5599d1d74ffbd27bc415",
        "655e559ad1d74ffbd27bc41c")
      ],
    user: {
      _id: "655ad72bd148ee58ab8d5871",
      name: "Izareo Madelame",
      image: izar,
    },
    comments: [],
    status: true,
    createdAt: "2024-10-20T11:12:03.368Z",
    updatedAt: "2023-11-22T19:25:15.100Z",
    __v: 0,
  },
];

export const CATEGORIES = [
  {
    label: "DN Return V1",
    color: "bg-[#e11d48]",
    text: "text-[#fff]",
    link: "https://dnreturn.fate-community.com/",
    icon: a,
  },
  {
    label: "DN Return V2",
    color: "bg-[#2563eb]",
    icon: b,
    link: "https://dnreturn.fate-community.com/",
  },
  {
    label: "Fate LostSage",
    color: "bg-[#000000]",
    icon: c,
    link: "https://lostsaga.fate-community.com/",
  },
  {
    label: "DN Fate",
    color: "bg-[#ca8a04]",
    icon: d,
    link: "https://dnfate.fate-community.com/",
  },
  {
    label: "DN Origin",
    color: "bg-[#9333ea]",
    icon: e,
  },
];

export const popular = {
  posts: [
    {
      _id: "655b3f037a397a2c8546c2f5",
      title: "Event PVP DWC DNOrigin",
      slug: "markdown-to-jsx-v6-is-now-available",
      img: "https://i.ytimg.com/vi/syaD57GhYhI/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD19wODbLLRN06sxWhygfG1cVISzA",
      cat: "DNOrigin",
      createdAt: "2024-10-20T11:12:03.368Z",
      views: 50,
    },
    {
      _id: "655ad816d148ee58ab8d58a1",
      title: "Fate x Slime Dragon Nest Return V2",
      slug: "Fate x Slime Dragon Nest Return V2",
      img: "https://news.fatduckdn.com/content/images/2023/09/PDN-2023-09-14-14-23-04-Thu.png",
      cat: "DNReturn V2",
      createdAt: "2023-11-20T03:52:54.673Z",
      views: 29,
    },
  ],
  writers: [
    {
      _id: "655ad72bd148ee58ab8d5871",
      name: "Izareo Madelame",
      image: izar,
      followers: 2,
    },
  ],
};

export const writer = {
  _id: "655ad72bd148ee58ab8d5871",
  name: "Izareo Madelame",
  email: "izareo@fate-.com",
  emailVerified: true,
  accountType: "Writer",
  image: izar,
  provider: "Codewave",
  createdAt: "2023-11-20T03:48:59.313Z",
  updatedAt: "2023-11-22T08:31:31.779Z",
  __v: 0,
  followers: [
    {
      _id: "655cde16a00890a1b49fcec2",
      followerId: "655acf151a4659413d8ab136",
    },
    {
      _id: "655dbc63079fbf7891ad8e0f",
      followerId: "655dbc30079fbf7891ad8df3",
    },
  ],
};

export const COMMENTS = [];
